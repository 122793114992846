import { MAPBOX_KEY } from 'configuration/mapboxKey';
import React, { useRef, useEffect, memo } from 'react';
import '../leaflet.css';

const L = window.L;

const mapOptions = {
  scrollwheel: true,
  navigationControl: true,
  mapTypeControl: true,
  scaleControl: true,
  draggable: true,
  zoomControl: true,
  fullscreenControl: true,
  streetViewControl: true,
  maxZoom: 18,
  scrollWheelZoom: true,
};

function getMarkerHtml(location) {
  let bgColor = '#e02020';
  if (location.deliveryDateTimestamp) {
    const timeDifference = Math.floor(
      (location.deliveryDateTimestamp - new Date().getTime()) / 60000
    );
    if (timeDifference > 59) {
      bgColor = '#5eb602';
    } else if (timeDifference >= 20) {
      bgColor = '#fd6c00';
    }
  }

  const address = location.address ?? '';
  const width = address.length > 20 ? 130 : 100;

  return `
  <div
    style="
      margin-top: -15px;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      width: ${width}px;
      height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    "
    >
      <section
        style="
          background-color: ${bgColor};
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 20px;
          font-weight: bold;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          position: relative;
        "
      >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.9167 7.83335V4.25002H14.1667V6.33335L10 2.66669L1.66669 9.91669L2.33335 10.6667L4.08335 9.16669V17.3334H15.9167V9.25002L17.6667 10.75L18.3334 10L15.9167 7.83335ZM14.9167 16.3334H12V11.3334H8.08335V16.3334H5.08335V8.33335L10 4.00002L14.9167 8.25002V16.3334Z" fill="white"/>
        </svg>
   
      
        <div
          style="
            position: absolute;
            top: 30px;
            left: 8px;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid ${bgColor};
            z-index: 1;
          "
        ></div>
        <div
          style="
            position: absolute;
            top: 35px;
            left: 11px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: ${bgColor};
            border: 1px solid white;
            z-index: 0;
          "
        ></div>
      </section>

      <section
        style="
          background-color: #fff;
          width: ${width - 30}px;
          height: 30px;
          text-align: center;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 5px;
        "
      >
        ${address}
      </section>
    </div>
  `;
}

function LeafletDeliveryBatches({
  locations,
  polygonLocations,
  addressLocation,
  onMarkerClick,
}) {
  const mapContainer = useRef(null);
  const mapRef = useRef(null);
  const layerGroup = useRef(null);
  const polygonGroup = useRef(null);

  useEffect(() => {
    if (!addressLocation?.lat || !addressLocation?.lng) return;

    const container = mapContainer.current;
    if (container != null) {
      container._leaflet_id = null;
    }
    L.mapbox.accessToken = `${MAPBOX_KEY}`;

    if (polygonLocations && Array.isArray(polygonLocations) && container) {
      mapRef.current = L.mapbox
        .map(container, null, mapOptions)
        .setView(
          polygonLocations[0]?.[0]?.lat && polygonLocations[0]?.[0]?.lng
            ? [
                Number(polygonLocations[0][0].lat),
                Number(polygonLocations[0][0].lng),
              ]
            : addressLocation?.lat && addressLocation?.lng
            ? [Number(addressLocation.lat), Number(addressLocation.lng)]
            : [],
          18
        )
        .addLayer(
          L.mapbox.styleLayer(
            'mapbox://styles/williamrichards/cjvc2w0fo6alc1fqxknvqjgoy',
            {
              tileSize: 256,
            }
          )
        );

      if (polygonLocations.length > 0) {
        const latLngs = polygonLocations
          .map((locations) =>
            locations.map((loc) => L.latLng(loc.lat, loc.lng))
          )
          .flat();
        const bounds = L.latLngBounds(latLngs);
        const options = {
          maxZoom: 18,
        };
        mapRef.current.fitBounds(bounds, options);
        polygonGroup.current = L.layerGroup().addTo(mapRef.current);
      }

      // create a polygon for each array
      polygonLocations.forEach((locations) => {
        const latLngs = locations.map((location) => [
          location.lat,
          location.lng,
        ]);
        L.polygon(latLngs, {
          color: '#047cfe',
          fillColor: '#00ffff',
          fillOpacity: 0.2,
        })
          .addTo(polygonGroup.current)
          .bindPopup(locations[0].address);
        // add markers with icons to the polygon layer group
        locations.forEach((location) => {
          L.marker([location.lat, location.lng], {
            icon: L.divIcon({
              html: getMarkerHtml(location),
            }),
          })
            .addTo(polygonGroup.current)
            .on('click', () => onMarkerClick?.(location.id));
        });
      });
    } else if (locations && Array.isArray(locations) && container) {
      mapRef.current = L.mapbox
        .map(container, null, mapOptions)
        .setView(
          locations[0]?.lat && locations[0]?.lng
            ? [Number(locations[0].lat), Number(locations[0].lng)]
            : addressLocation?.lat && addressLocation?.lng
            ? [Number(addressLocation.lat), Number(addressLocation.lng)]
            : [],
          18
        )
        .addLayer(
          L.mapbox.styleLayer(
            'mapbox://styles/williamrichards/cjvc2w0fo6alc1fqxknvqjgoy',
            {
              tileSize: 256,
            }
          )
        );

      if (locations.length > 0) {
        const latLngs = locations.map((loc) => L.latLng(loc.lat, loc.lng));
        const bounds = L.latLngBounds(latLngs);
        const options = {
          maxZoom: 18,
        };
        mapRef.current.fitBounds(bounds, options);
        layerGroup.current = L.layerGroup().addTo(mapRef.current);
      }

      // create a marker for each location
      locations.forEach((location) => {
        L.marker([location.lat, location.lng], {
          rotationOrigin: 'center',
          icon: L.divIcon({
            html: getMarkerHtml(location),
          }),
        })
          .addTo(layerGroup.current)
          .on('click', () => onMarkerClick?.(location.id));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations?.length, polygonLocations?.length, addressLocation]);

  return <div ref={mapContainer} />;
}

export default memo(LeafletDeliveryBatches, () => true);
