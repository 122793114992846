import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import '../leaflet.css';
import { MAPBOX_KEY } from 'configuration/mapboxKey';

const L = window.L;

class LeafletGeneric extends PureComponent {
  constructor(props) {
    super(props);
    this.map = {};
    this.mapContainer = React.createRef();
  }

  componentDidMount() {
    var container = this.mapContainer.current;
    if (container != null) {
      container._leaflet_id = null;
    }
    L.mapbox.accessToken = `${MAPBOX_KEY}`;
    if (
      typeof this.props.delivery === 'object' &&
      this.props.delivery.lat &&
      this.props.delivery.lng &&
      container
    ) {
      this.map = L.mapbox
        .map(container, null, {
          scrollwheel: false,
          navigationControl: false,
          mapTypeControl: false,
          scaleControl: false,
          draggable: false,
          zoomControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          maxZoom: 16,
          scrollWheelZoom: false,
        })
        .setView(
          [Number(this.props.delivery.lat), Number(this.props.delivery.lng)],
          16
        )
        .addLayer(
          L.mapbox.styleLayer(
            'mapbox://styles/williamrichards/cjvc2w0fo6alc1fqxknvqjgoy'
          )
        );

      this.layerGroup = L.layerGroup().addTo(this.map);

      //Showing only store when order is in kitchen, on the way or cancelled
      this.delivery = L.marker(
        [this.props.delivery.lat, this.props.delivery.lng],
        {
          rotationOrigin: 'center',
          icon: L.icon({
            iconUrl: `https://storage.googleapis.com/next-order-media/assets/marker.svg`,
            iconSize: [40, 50], // size of the icon
            iconAnchor: [20, 50],
          }),
        }
      ).addTo(this.layerGroup);
    }
    if (
      typeof this.props.location === 'object' &&
      this.props.location.lat &&
      this.props.location.long
    ) {
      this.map = L.mapbox
        .map(this.props.id, null, {
          scrollwheel: false,
          navigationControl: false,
          mapTypeControl: false,
          scaleControl: false,
          draggable: false,
          zoomControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          maxZoom: 16,
          scrollWheelZoom: false,
        })
        .setView(
          [Number(this.props.location.lat), Number(this.props.location.long)],
          16
        )
        .addLayer(
          L.mapbox.styleLayer(
            'mapbox://styles/williamrichards/cjvc2w0fo6alc1fqxknvqjgoy',
            {
              tileSize: 256,
            }
          )
        );

      this.layerGroup = L.layerGroup().addTo(this.map);

      //Showing only store when order is in kitchen, on the way or cancelled
      this.store = L.marker(
        [this.props.location.lat, this.props.location.long],
        {
          rotationOrigin: 'center',
          icon: L.icon({
            iconUrl: `https://storage.googleapis.com/next-order-media/assets/S%2BP%2BI%2Bcon.svg`,
            iconSize: [40, 50], // size of the icon
            iconAnchor: [20, 50],
          }),
        }
      ).addTo(this.layerGroup);
    }
  }

  componentWillUnmount() {
    // this.layerGroup.clearLayers();
  }

  componentDidUpdate(prevPorps) {
    if (
      this.props.currentOrder &&
      prevPorps.currentOrder &&
      this.props.currentOrder.stage !== prevPorps.currentOrder.stage
    ) {
      this.map.invalidateSize();
    }
  }

  render() {
    return <div ref={this.mapContainer} id={this.props.id} />;
  }
}

LeafletGeneric.propTypes = {
  location: PropTypes.any,
  delivery: PropTypes.any,
  currentOrder: PropTypes.any,
};

export default LeafletGeneric;
