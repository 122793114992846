import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getSplitPaymentsCashAmountModalButtonStatus } from 'util/order-flow-utils';
import { getServiceID } from 'util/utils';
import { v4 as uuidv4 } from 'uuid';
import { ApiStatusEnum, paymentTypeEnum } from '../../configuration/enums';
import {
  authenticateStaff,
  resetCurrentOrder,
  setOrderPayments,
  setSplitOrderServiceId,
  setSplitOrderSessionId,
  setSplitOrderId,
  updateCurrentOrder,
} from '../../pages/Dashboard/action';
import {
  currencyCodeSelector,
  orderPaymentsSelector,
  orderPaymentsTotalPaidSelector,
  pinPadsSelector,
  restaurantIdSelector,
  selectedDrawerIdSelector,
  selectedDrawerPrinterIdSelector,
  selectedPinPadIdSelector,
  splitOrderServiceIdSelector,
  splitOrderSessionIdSelector,
  splitOrderIdSelector,
  storeConfigSelector,
  storeTimeZoneSelector,
} from '../../pages/Dashboard/selectors';
import {
  addPrinterCommandApi,
  placeOrderApi,
  printBillCommand,
  settleOrderApi,
} from '../../services/ordersServices';
import CashAmountModal from '../CashAmountModal';
import SplitPaymentsModal from './SplitPaymentsModal';
import { _auth, _firestore } from '../../firebase';
import { nextOrderConfig } from 'configuration/nextOrderConfig';

const SplitPaymentsRoot = ({
  currentOrder,
  closeFlowStatus,
  isOrdersPage = false,
}) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const [windcaveTransactionRef, setWindcaveTransactionRef] = useState('');
  const [windcaveInfoMessage, setWindcaveInfoMessage] = useState('');
  const [windcaveButtonOne, setWindcaveButtonOne] = useState('');
  const [windcaveButtonTwo, setWindcaveButtonTwo] = useState('');
  const [isWindcaveProcessing, setIsWindcaveProcessing] = useState(false);
  // const [windCaveCompleteStatus, setWindCaveCompleteStatus] = useState(0);

  const windCaveCompleteStatusRef = useRef(0);
  const [windcaveActionButtonPressed, setWindcaveActionButtonPressed] =
    useState(false);

  const currSessionId = currentOrder?.sessionId || '';

  const currServiceId = currentOrder?.serviceId || '';

  const currOrderId = currentOrder?._id || currentOrder?.id || '';

  const storeConfig = useSelector(storeConfigSelector);
  const currencyCode = useSelector(currencyCodeSelector);
  const restaurantId = useSelector(restaurantIdSelector);
  const timeZone = useSelector(storeTimeZoneSelector);
  const selectedPinPadId = useSelector(selectedPinPadIdSelector);
  const selectedDrawerId = useSelector(selectedDrawerIdSelector);
  const orderPayments = useSelector(orderPaymentsSelector);
  const orderPaymentsTotalPaid = useSelector(orderPaymentsTotalPaidSelector);
  const selectedDrawerPrinterId = useSelector(selectedDrawerPrinterIdSelector);
  const splitOrderSessionId = useSelector(splitOrderSessionIdSelector);
  const splitOrderId = useSelector(splitOrderIdSelector);
  const splitOrderServiceId = useSelector(splitOrderServiceIdSelector);
  const pinPads = useSelector(pinPadsSelector);
  const apiLoading = useSelector((state) => state.dashboardReducer.apiLoading);
  const isCancelTransactionApiLoading = useSelector(
    (state) => state.dashboardReducer.isCancelTransactionApiLoading
  );
  const windcaveUser = useSelector(
    (state) => state.dashboardReducer.terminalSettings?.user || 'None'
  );

  const openDrawerForCardTransactions = useSelector(
    (state) => state.dashboardReducer.orderSetup?.openDrawerForCardTransactions
  );

  const accessToken = useSelector(
    (state) => state.dashboardReducer?.accessToken
  );

  const currentSelectedTerminal = pinPads?.find(
    (pinPad) => pinPad._id === selectedPinPadId
  );

  const payableAmount = React.useMemo(() => {
    const amountTobePaid =
      Number(currentOrder.payableAmount) - orderPaymentsTotalPaid;
    if (amountTobePaid < 0) return 0;
    return Number(amountTobePaid.toFixed(2));
  }, [currentOrder.payableAmount, orderPaymentsTotalPaid]);

  // FIXME: Moving this to a reducer might be a good idea
  const [showSplitPaymentsStatusModal, setShowSplitPaymentsStatusModal] =
    React.useState(true);
  const [showCashAmountModal, setShowCashAmountModal] = React.useState(false);

  const [paymentOptionSelected, setPaymentOptionSelected] =
    React.useState(null);
  const [loadingStatus, setLoadingStatus] = React.useState(ApiStatusEnum.IDLE);
  const [printingLoadingStatus, setPrintingLoadingStatus] = React.useState(
    ApiStatusEnum.IDLE
  );
  const [hasOrderBeenCompleted, setHasOrderBeenCompleted] =
    React.useState(false);
  const [orderId, setOrderId] = React.useState('');
  const [changeAmount, setChangeAmount] = React.useState(0);

  React.useEffect(() => {
    if (currSessionId) {
      dispatch(setSplitOrderSessionId(currSessionId));
    } else if (!splitOrderSessionId) {
      dispatch(setSplitOrderSessionId(uuidv4()));
    }
  }, [dispatch, splitOrderSessionId, currSessionId]);

  React.useEffect(() => {
    if (currOrderId || splitOrderId) {
      dispatch(setSplitOrderId(currOrderId || splitOrderId));
    }
  }, [currOrderId, splitOrderId]);

  React.useEffect(() => {
    if (currServiceId) {
      dispatch(setSplitOrderServiceId(currServiceId));
    } else if (!splitOrderServiceId) {
      dispatch(setSplitOrderServiceId(getServiceID(currServiceId)));
    }
  }, [dispatch, splitOrderServiceId, currServiceId]);

  const handleSplitPaymentModalClose = () => {
    if (!hasOrderBeenCompleted) {
      setShowSplitPaymentsStatusModal(false);
      closeFlowStatus();
    }
  };

  const handleSplitPaymentOptionClick = (optionSelected) => {
    setPaymentOptionSelected(optionSelected);
    setShowCashAmountModal(true);
  };

  const handleCloseCashAmountModal = () => {
    if (loadingStatus !== ApiStatusEnum.IDLE) return;
    setShowCashAmountModal(false);
  };

  const handleWindCaveSplitPaymentApiCall = async (
    requestObj,
    apiHandler,
    funcType
  ) => {
    setLoadingStatus(ApiStatusEnum.FETCHING);
    setIsWindcaveProcessing(true);
    //apiHandler is placeOrderApi
    const response = await apiHandler(requestObj);
    const { success, orderId, transactionReference } = response;
    dispatch(setSplitOrderId(orderId));

    if (!success) {
      //Place Order API Failed
      setLoadingStatus(ApiStatusEnum.FAILED);
      setChangeAmount('');

      setTimeout(() => {
        setIsWindcaveProcessing(false);
        setPaymentOptionSelected(null);
        setShowCashAmountModal(false);
        setLoadingStatus(ApiStatusEnum.IDLE);
      }, [2500]);
    }

    const newServiceId = getServiceID(uuidv4());
    dispatch(setSplitOrderServiceId(newServiceId));

    let listenWindcaveStatusUnsubscribe;

    if (orderId && transactionReference) {
      localStorage.setItem('transactionReference', transactionReference);

      //Listen WindCave collection
      const windCaveStatusRef = _firestore
        .collection('WindcaveStatus')
        .doc(transactionReference);

      listenWindcaveStatusUnsubscribe = windCaveStatusRef.onSnapshot(
        async (querySnapshot) => {
          let windCaveStatusObj = querySnapshot.data();

          const dataFromCache =
            funcType === 'settleOrderApi'
              ? false
              : !!querySnapshot.metadata.fromCache;

          if (!dataFromCache) {
            if (windCaveStatusObj?.transactionReference) {
              //Setting below state because we gonna use this in button action
              setWindcaveTransactionRef(transactionReference);

              setWindcaveInfoMessage(
                `${windCaveStatusObj['displayLine1'] || ''} ${
                  windCaveStatusObj['displayLine2'] || ''
                }`
              );

              setWindcaveButtonOne(windCaveStatusObj['button1'] || '');
              setWindcaveButtonTwo(windCaveStatusObj['button2'] || '');

              if (windCaveStatusObj?.complete === 1) {
                //payment is complete
                // setWindCaveCompleteStatus(1);
                windCaveCompleteStatusRef.current = 1;

                if (windCaveStatusObj?.AP === 1) {
                  listenWindcaveStatusUnsubscribe();
                  // Listen Order Data
                  const orderRef = _firestore.collection('Orders').doc(orderId);
                  setTimeout(async () => {
                    const doc = await orderRef.get();
                    const obj = doc.data();

                    const paidAmount = obj?.payments?.reduce(
                      (accu, currValue) => {
                        return accu + currValue?.amount;
                      },
                      0
                    );

                    const payableAmount = Number(obj?.payableAmount);

                    const isOrderPaidCompletely =
                      Number(paidAmount.toFixed(2)) ===
                      Number(payableAmount.toFixed(2));

                    if (obj?.isError) {
                      setLoadingStatus(ApiStatusEnum.FAILED);
                      setIsWindcaveProcessing(false);
                      setChangeAmount('');
                    } else if (
                      !isOrderPaidCompletely &&
                      obj?.orderStatus !== '-1'
                    ) {
                      setLoadingStatus(ApiStatusEnum.RESOLVED);
                      setIsWindcaveProcessing(false);
                      dispatch(
                        updateCurrentOrder({
                          ...currentOrder,
                          isSplit: true,
                        })
                      );
                    } else if (
                      isOrderPaidCompletely &&
                      obj?.orderStatus !== '-1'
                    ) {
                      setIsWindcaveProcessing(false);
                      setOrderId(orderId);
                      setHasOrderBeenCompleted(true);
                    }

                    setWindcaveButtonOne('');
                    setWindcaveButtonTwo('');

                    setTimeout(() => {
                      localStorage.removeItem('transactionReference');
                      windCaveCompleteStatusRef.current = 0;
                      setIsWindcaveProcessing(false);
                      dispatch(setOrderPayments(obj?.payments));
                      setPaymentOptionSelected(null);
                      setShowCashAmountModal(false);
                      setLoadingStatus(ApiStatusEnum.IDLE);
                    }, [isOrderPaidCompletely ? 1000 : 3500]);
                  }, 3000);
                } else if (windCaveStatusObj?.AP === 0) {
                  setLoadingStatus(ApiStatusEnum.FAILED);
                  setIsWindcaveProcessing(false);
                  setChangeAmount('');

                  setWindcaveButtonOne('');
                  setWindcaveButtonTwo('');

                  setTimeout(() => {
                    localStorage.removeItem('transactionReference');
                    windCaveCompleteStatusRef.current = 0;
                    setIsWindcaveProcessing(false);
                    setPaymentOptionSelected(null);
                    setShowCashAmountModal(false);
                    setLoadingStatus(ApiStatusEnum.IDLE);
                  }, [2000]);
                }
              }

              if (!windCaveStatusObj?.station) {
                // windcave transaction cancelled
                listenWindcaveStatusUnsubscribe();

                setLoadingStatus(ApiStatusEnum.FAILED);
                setWindcaveButtonTwo('');
              }
            }
          }
        }
      );
    } else {
      // Place Order API Failed
      setLoadingStatus(ApiStatusEnum.FAILED);
      setIsWindcaveProcessing(false);
      setChangeAmount('');
    }
  };

  const handleSplitPaymentApiCall = async (requestObj, apiHandler) => {
    const isPaymentTypeCash =
      paymentOptionSelected.type === paymentTypeEnum.CASH;

    if (isPaymentTypeCash && selectedDrawerId !== '000-000-000') {
      await handleSplitPaymentPrinterDrawer({
        printReceipt: false,
        openCashDrawer: isPaymentTypeCash,
        newOrderId: '',
      });
    }
    setLoadingStatus(ApiStatusEnum.FETCHING);
    const response = await apiHandler(requestObj,accessToken);
    const {
      success,
      orderPayments,
      orderId,
      orderFullyPaidPostPayment = false,
    } = response;

    if (!success) {
      setLoadingStatus(ApiStatusEnum.FAILED);
      setChangeAmount('');
    }

    const newServiceId = getServiceID(uuidv4());
    dispatch(setSplitOrderServiceId(newServiceId));

    // START: Printer/Drawer Logic
    const isOrderCompletedPostAPI = orderFullyPaidPostPayment ? true : false;
    const shouldPrinterBeCalled =
      isOrderCompletedPostAPI || isPaymentTypeCash ? true : false;

    if (shouldPrinterBeCalled) {
      await handleSplitPaymentPrinterDrawer({
        printReceipt: isOrderCompletedPostAPI,
        openCashDrawer: false,
        newOrderId: orderId,
      });
    }
    // END: Printer/Drawer Logic

    // On order completion set current order for user actions in the end modal status
    if (isOrderCompletedPostAPI) {
      const currentOrderId = orderId || currentOrder._id;
      setOrderId(currentOrderId);
      setHasOrderBeenCompleted(true);
    }

    if (success) {
      setLoadingStatus(ApiStatusEnum.RESOLVED);
      dispatch(updateCurrentOrder({ ...currentOrder, isSplit: true }));
      dispatch(setSplitOrderId(orderId));
    }

    // Close the Cash Amount Modal a second after receiving the response
    setTimeout(() => {
      if (success) {
        dispatch(setOrderPayments(orderPayments));
      }
      setPaymentOptionSelected(null);
      setShowCashAmountModal(false);
      setLoadingStatus(ApiStatusEnum.IDLE);
    }, [success ? 1000 : 3500]);
  };

  const handleWindcaveButton = async (
    value,
    buttonName,
    transactionReference
  ) => {
    setWindcaveActionButtonPressed(true);
    const requestObj = {
      txnId: transactionReference,
      station: selectedPinPadId,
      user: windcaveUser,
      val: value,
      name: buttonName,
    };
    const windCaveActionResponse = await fetch(
      `${nextOrderConfig?.baseUrl}/windcave/v1/buttonAction`,
      {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestObj),
      }
    );

    const windCaveActionResponseJson = await windCaveActionResponse.json();

    setWindcaveActionButtonPressed(false);
  };

  const handleTenderClick = async (amount) => {
    if (loadingStatus !== ApiStatusEnum.IDLE) return;

    setLoadingStatus(ApiStatusEnum.FETCHING);
    let amountToSubmit = amount;
    if (amount > payableAmount) {
      amountToSubmit = payableAmount;
      setChangeAmount(amount - payableAmount);
    }

    const {
      type: paymentType,
      paymentTypeId,
      paymentTypeName,
    } = paymentOptionSelected;

    if (!isOrdersPage) {
      const newOrderObject = {
        ...currentOrder,
        paymentType,
        storeId: storeConfig?.storeId,
        countryIdentifier: storeConfig.countryIdentifier,
        restaurantId,
        isSplit: true,
        ...(paymentTypeId ? { paymentTypeId } : null),
        ...(paymentTypeName ? { paymentTypeName } : null),
        id: splitOrderId || '',
      };

      const isPaymentTypeCash =
        paymentOptionSelected.type === paymentTypeEnum.CASH;

      let placeOrderRequestObj = {
        order: newOrderObject,
        payment: {
          type: paymentType,
          amount: parseInt((Number(amountToSubmit) * 100).toFixed(2), 10),
          isRefund: false,
          merchantCode: isPaymentTypeCash
            ? 400
            : currentSelectedTerminal?.merchantCode || 400,
          storePinPadId: selectedPinPadId,
          sessionId: splitOrderSessionId,
          serviceId: splitOrderServiceId,
          user: windcaveUser,
        },
      };

      if (currentSelectedTerminal?.merchantCode === 600 && !isPaymentTypeCash) {
        // windcave call for split payment
        handleWindCaveSplitPaymentApiCall(
          placeOrderRequestObj,
          placeOrderApi,
          'placeOrderApi'
        );
      } else {
        handleSplitPaymentApiCall(placeOrderRequestObj, placeOrderApi);
      }
    } else {
      const isPaymentTypeCash =
        paymentOptionSelected.type === paymentTypeEnum.CASH;

      const settleOrderRequestObject = {
        orderId: currentOrder._id,
        paymentType,
        drawerId: selectedDrawerId,
        merchantCode: isPaymentTypeCash
          ? 400
          : currentSelectedTerminal?.merchantCode || 400,
        storePinPadId: selectedPinPadId,
        amount: amountToSubmit,
        isRefund: false,
        isSplit: true,
        storeId: storeConfig?.storeId,
        countryIdentifier: storeConfig.countryIdentifier,
        currencyCode,
        sessionId: splitOrderSessionId,
        serviceId: splitOrderServiceId,
        user: windcaveUser,
      };

      if (paymentTypeId) {
        settleOrderRequestObject.paymentTypeId = paymentTypeId;
      }

      if (paymentTypeName) {
        settleOrderRequestObject.paymentTypeName = paymentTypeName;
      }

      if (currentSelectedTerminal?.merchantCode === 600 && !isPaymentTypeCash) {
        // windcave call for split payment settleOrder API

        handleWindCaveSplitPaymentApiCall(
          settleOrderRequestObject,
          settleOrderApi,
          'settleOrderApi'
        );
      } else {
        handleSplitPaymentApiCall(settleOrderRequestObject, settleOrderApi);
      }
    }
  };

  const handleSplitPaymentPrinterDrawer = async ({
    printReceipt = true,
    openCashDrawer = false,
    newOrderId = '',
  }) => {
    const printCommandRequestObj = {
      printerId: selectedDrawerPrinterId,
      orderId: newOrderId,
      storeId: storeConfig?.storeId,
      restaurantId,
      printReceipt,
      openCashDrawer: openDrawerForCardTransactions ? true : openCashDrawer,
      timeZone,
    };
    if (selectedDrawerPrinterId) {
      return await addPrinterCommandApi(printCommandRequestObj);
    }
    return;
  };

  // To be used after an order or payment has been completed
  const handlePrintBill = async () => {
    if (orderId && selectedDrawerPrinterId) {
      let printObj = {
        printerId: selectedDrawerPrinterId,
        restaurantId,
        orderId,
        storeId: storeConfig?.storeId,
      };
      await printBillCommand(printObj);
    }
  };

  const handleFinishSale = () => {
    if (currentOrder.isEditOrder || currentOrder.iteration) {
      history.push('/orders');
    } else {
      dispatch(authenticateStaff(false));
      if (!isOrdersPage) {
        history.push('/');
      } else {
        closeFlowStatus();
      }
    }
    dispatch(resetCurrentOrder());
    dispatch(setSplitOrderSessionId(''));
    dispatch(setSplitOrderId(''));
    setShowSplitPaymentsStatusModal(false);
    closeFlowStatus();
  };

  // To be used in the SplitPaymentsModal
  const handlePrintReceipt = async () => {
    setPrintingLoadingStatus(ApiStatusEnum.LOADING);
    await handlePrintBill();
    setPrintingLoadingStatus(ApiStatusEnum.RESOLVED);
    setTimeout(() => {
      handleFinishSale();
    }, 1000);
  };

  const handleModalButtonStatus = React.useCallback(
    (total) =>
      getSplitPaymentsCashAmountModalButtonStatus(
        Number(total),
        payableAmount,
        paymentOptionSelected,
        hasOrderBeenCompleted
      ),
    [payableAmount, paymentOptionSelected, hasOrderBeenCompleted]
  );

  const apiFailedError =
    loadingStatus === ApiStatusEnum.FAILED
      ? 'Payment failed, please try again'
      : '';

  return (
    <>
      {showSplitPaymentsStatusModal && (
        <SplitPaymentsModal
          modalStatus={showSplitPaymentsStatusModal}
          toggleModal={handleSplitPaymentModalClose}
          payableAmount={payableAmount}
          orderPayments={orderPayments}
          changeAmount={changeAmount}
          handleSplitPaymentOptionClick={handleSplitPaymentOptionClick}
          hasOrderBeenCompleted={hasOrderBeenCompleted}
          printingLoadingStatus={printingLoadingStatus}
          handleFinishSaleClick={handleFinishSale}
          handlePrintReceiptClick={handlePrintReceipt}
          loading={apiLoading || isCancelTransactionApiLoading}
        />
      )}
      {showCashAmountModal && (
        <CashAmountModal
          modalStatus={showCashAmountModal}
          toggleModal={handleCloseCashAmountModal}
          handleTenderClick={handleTenderClick}
          showAmountInTenderButton={true}
          isLoading={loadingStatus === ApiStatusEnum.FETCHING}
          isResolved={loadingStatus === ApiStatusEnum.RESOLVED}
          isFailed={loadingStatus === ApiStatusEnum.FAILED}
          handleShouldShowButton={handleModalButtonStatus}
          shouldBeEditable={loadingStatus === ApiStatusEnum.IDLE}
          paymentTypeName={paymentOptionSelected?.name}
          customErrors={apiFailedError}
          windcaveButtonOne={windcaveButtonOne}
          windcaveButtonTwo={windcaveButtonTwo}
          handleWindcaveButton={handleWindcaveButton}
          windcaveTransactionRef={windcaveTransactionRef}
          windcaveActionButtonPressed={windcaveActionButtonPressed}
          isWindcaveProcessing={isWindcaveProcessing}
        />
      )}
    </>
  );
};

export default SplitPaymentsRoot;
