const actionTypes = {
  Initialize: 'INITIALIZE',
  ToggleFullScreen: 'TOGGLE_FULLSCREEN',
  ToggleVirtualKeyboard: 'TOGGLE_VIRTUAL_KEYBOARD',
  ToggleBlurEffect: 'TOGGLE_BLUR_EFFECT',
  ToggleSingleOption: 'TOGGLE_SINGLE_OPTION',
  AppLoaded: 'APP_LOADED',
  InitNewOrderItem: 'NEW_ORDER_ITEM/INIT',
  toggleItemDetailsModal: 'NEW_ORDER_ITEM/ITEM_DETAILS_MODAL',
  updateItemSizesOption: 'NEW_ORDER_ITEM/UPDATE_SIZES_OPTION',
  updateItemOtherOption: 'NEW_ORDER_ITEM/UPDATE_OTHER_OPTION',
  updateIngredientStatus: 'NEW_ORDER_ITEM/UPDATE_INGREDIENT_STATUS',
  changeItemQuantity: 'NEW_ORDER_ITEM/CHANGE_ITEM_QUANTITY',
  changeItemGuestInfo: 'NEW_ORDER_ITEM/CHANGE_ITEM_GUEST_INFO',
  changeItemNotes: 'NEW_ORDER_ITEM/CHANGE_ITEM_NOTES',
  changePickupTime: 'NEW_ORDER_ITEM/CHANGE_PICKUP_TIME',
  OpenMenuItem: 'OPEN_MENU_ITEM',
  ToggleItemLargePhotoView: 'TOGGLE_ITEM_LARGE_PHOTO_VIEW',
  UpdateMenuItem: 'UPDATE_MENU_ITEM',
  ResetMenuItem: 'RESET_MENU_ITEM',
  UpdateCurrentOrder: 'UPDATE_CURRENT_ORDER',
  ResetCurrentOrder: 'RESET_CURRENT_ORDER',
  UpdateCashModalTitle: 'UPDATE_CASH_MODAL_TITLE',
  UpdateCurrentOrderDeliveryLocation: 'UPDATE_CURRENT_ORDER_DELIVERY_LOCATION',
  ResetCurrentOrderDeliveryLocation: 'RESET_CURRENT_ORDER_DELIVERY_LOCATION',
  SetActionsModalConfig: 'SET_ACTIONS_MODAL_CONFIG',
  ResetActionsModalConfig: 'RESET_ACTIONS_MODAL_CONFIG',
  SetItemActionsModalConfig: 'SET_ITEM_ACTIONS_MODAL_CONFIG',
  ResetItemActionsModalConfig: 'RESET_ITEM_ACTIONS_MODAL_CONFIG',
  UpdateCurrentSpecialsItem: 'UPDATE_CURRENT_SPECIALS_ITEM',
  ResetCurrentSpecialsItem: 'RESET_CURRENT_SPECIALS_ITEM',
  UpdateCurrentSpecialsMenuItem: 'UPDATE_CURRENT_SPECIALS_MENU_ITEM',
  ToggleSpecialsCategoryEditMode: 'TOGGLE_SPECIALS_CATEGORY_EDIT_MODE',
  UpdateCurrentSpecialsCategoryItem: 'UPDATE_CURRENT_SPECIALS_CATEGORY_ITEM',
  UpdateCurrentHalfHalfData: 'UPDATE_CURRENT_HALF_HALF_DATA',
  SET_HALF_HALF_MENU_ITEMS: 'SET_HALF_HALF_MENU_ITEMS',
  SetSingleHalfFlow: 'SET_SINGLE_HALF_FLOW',
  SetShowPizzaActionButton: 'SET_SHOW_PIZZA_ACTION_BUTTON',
  ResetCurrentHalfHalfData: 'RESET_CURRENT_HALF_HALF_DATA',
  TOGGLE_HALF_SELECTED_SIZE: 'TOGGLE_HALF_SELECTED_SIZE',
  UpdateCurrentHalfHalfItem: 'UPDATE_CURRENT_HALF_HALF_ITEM',
  ToggleHalfHalfEditMode: 'TOGGLE_HALF_HALF_EDIT_MODE',
  SetHalfHalfForModify: 'SET_HALF_HALF_FOR_MODIFY',
  UpdateCurrentFourQuarterData: 'UPDATE_CURRENT_FOUR_QUARTER_DATA',
  ResetCurrentFourQuarterData: 'RESET_CURRENT_FOUR_QUARTER_DATA',
  ToggleFourQuarterSelectedSize: 'TOGGLE_FOUR_QUARTER_SELECTED_SIZE',
  UpdateCurrentFourQuarterItem: 'UPDATE_CURRENT_FOUR_QUARTER_ITEM',
  ToggleFourQuarterEditMode: 'TOGGLE_FOUR_QUARTER_EDIT_MODE',
  ResetSpecialsCurrentHalfHalfData: 'RESET_SPECIALS_CURRENT_HALF_HALF_DATA',
  ResetSpecialsCurrentFourQuarterData:
    'RESET_SPECIALS_CURRENT_FOUR_QUARTER_DATA',
  ToggleSpecialsHalfSelectedSize: 'TOGGLE_SPECIALS_HALF_SELECTED_SIZE',
  ToggleSpecialsFourQuarterSelectedSize:
    'TOGGLE_SPECIALS_FOUR_QUARTER_SELECTED_SIZE',
  UpdateSpecialsCurrentHalfHalfItem: 'UPDATE_SPECIALS_CURRENT_HALF_HALF_ITEM',
  UpdateSpecialsHalfHalfIndex: 'UPDATE_SPECIALS_HALF_HALF_INDEX',
  UpdateSpecialsCurrentFourQuarterItem:
    'UPDATE_SPECIALS_CURRENT_FOUR_QUARTER_ITEM',
  ToggleSpecialsHalfHalfEditMode: 'TOGGLE_SPECIALS_HALF_HALF_EDIT_MODE',
  ToggleSpecialsFourQuarterEditMode: 'TOGGLE_SPECIALS_FOUR_QUARTER_EDIT_MODE',
  UpdateSpecialsHalfHalfData: 'UPDATE_SPECIALS_HALF_HALF_DATA',
  UpdateSpecialsFourQuarterData: 'UPDATE_SPECIALS_FOUR_QUARTER_DATA',
  AuthenticateStaff: 'AUTHENTICATE_STAFF',
  SetActiveStaffMember: 'SET_ACTIVE_STAFF_MEMBER',
  SetGoToRouteAfterAuth: 'SET_GO_TO_ROUTE_AFTER_AUTH',
  ScreenSizes: 'SCREEN_SIZES',
  UPDATE_STORE_CONFIG: 'UPDATE_STORE_CONFIG',
  UpdateAllMapOrder: 'UPDATE_ALL_MAP_ORDER',
  cashAmountChange: 'CASH_AMOUNT_CHANGE',
  cashPaid: 'CASH_PAID',
  toggleOrderModal: 'TOGGLE_ORDER_MODAL',
  toggleSplitDiscountModal: 'TOGGLE_SPLIT_DISCOUNT_MODAL',
  toggleSurchargeModal: 'TOGGLE_SURCHARGE_MODAL',
  //toggleItemViewModal: 'TOGGLE_ITEM_VIEW_MODAL',
  applyDiscount: 'APPLY_DISCOUNT',
  SET_ORDER_TYPE_CONFIG: 'SET_ORDER_TYPE_CONFIG',
  APPLY_DISCOUNT: 'APPLY_DISCOUNT',
  GET_DISCOUNT: 'GET_DISCOUNT',
  GET_DISCOUNT_SUCCESS: 'GET_DISCOUNT_SUCCESS',
  GET_DISCOUNT_FAILED: 'GET_DISCOUNT_FAILED',
  applyVoucher: 'APPLY_VOUCHER',
  GET_VOUCHER: 'GET_VOUCHER',
  GET_VOUCHER_SUCCESS: 'GET_VOUCHER_SUCCESS',
  GET_VOUCHER_FAILED: 'GET_VOUCHER_FAILED',
  SWITCH_ORDER_TYPE: 'SWITCH_ORDER_TYPE',
  UPDATE_STATE_ESCAPE_HATCH_DO_NOT_USE_THIS:
    'UPDATE_STATE_ESCAPE_HATCH_DO_NOT_USE_THIS',
  TOGGLE_HALF_HALF_SELECTED_VARIANT: 'TOGGLE_HALF_HALF_SELECTED_VARIANT',
  GET_PINPADS: 'GET_PINPADS',
  GET_WINDCAVE_USER_LIST: 'GET_WINDCAVE_USER_LIST',
  GET_TABLES: 'GET_TABLES',
  GET_DRAWERS: 'GET_DRAWERS',
  SET_ORDER_PAYMENTS: 'SET_ORDER_PAYMENTS',
  LOAD_TERMINAL_SETTING: 'LOAD_TERMINAL_SETTING',
  SET_ORDER_BACK_MODAL: 'SET_ORDER_BACK_MODAL',
  SET_DISABLE_SPECIAL_DISCOUNT_MODAL: 'SET_DISABLE_SPECIAL_DISCOUNT_MODAL',
  GET_ORDER_SETUP: 'GET_ORDER_SETUP',
  GET_PRODUCT_SETUP: 'GET_PRODUCT_SETUP',
  SET_CURRENT_MENU_ITEM_NOTES: 'SET_CURRENT_MENU_ITEM_NOTES',
  SET_CURRENT_HALF_HALF_NOTES: 'SET_CURRENT_HALF_HALF_NOTES',
  OPEN_ITEM_NOTES_MODAL: 'OPEN_ITEM_NOTES_MODAL',
  REFRESH_TO_UPDATE_POS_VERSION: 'REFRESH_TO_UPDATE_POS_VERSION',
  LOAD_CLOSE_DATES: 'LOAD_CLOSE_DATES',
  LOAD_PUBLIC_HOLIDAYS: 'LOAD_PUBLIC_HOLIDAYS',
  LOAD_SHIFTS: 'LOAD_SHIFTS',
  LOAD_SUBURBS: 'LOAD_SUBURBS',
  LOAD_REFUND_REASONS: 'LOAD_REFUND_REASONS',
  LOAD_SPECIALS: 'LOAD_SPECIALS',
  LOAD_SOLDOUTS_ITEM: 'LOAD_SOLDOUTS_ITEM',
  LOAD_CATEGORIES: 'LOAD_CATEGORIES',
  LOAD_CREDIT_CARDS_CHARGES: 'LOAD_CREDIT_CARDS_CHARGES',
  LOAD_MENUITEMS: 'LOAD_MENUITEMS',
  LOAD_MENUITEM_SIZES: 'LOAD_MENUITEM_SIZES',
  LOAD_MENUITEM_MODIFIERS: 'LOAD_MENUITEM_MODIFIERS',
  LOAD_MENUITEM_SUBMODIFIERS: 'LOAD_MENUITEM_SUBMODIFIERS',
  SET_STRIPE_CARD_PAYMENTS: 'SET_STRIPE_CARD_PAYMENTS',
  SET_PAYMENT_TYPES: 'SET_PAYMENT_TYPES',
  SET_SPLIT_ORDER_SESSION_ID: 'SET_SPLIT_ORDER_SESSION_ID',
  SET_SPLIT_ORDER_ID: 'SET_SPLIT_ORDER_ID',
  SET_SPLIT_ORDER_SERVICE_ID: 'SET_SPLIT_ORDER_SERVICE_ID',
  SET_CURRENT_ORDER_SELECTED_DISCOUNT: 'SET_CURRENT_ORDER_SELECTED_DISCOUNT',
  API_LOADING: 'API_LOADING',
  IS_CANCEL_TRANSACTION_API_LOADING: 'IS_CANCEL_TRANSACTION_API_LOADING',
  SET_INCOMING_CALLS_NOTIFICATIONS: 'SET_INCOMING_CALLS_NOTIFICATIONS',
  SET_ACCEPTED_CALL: 'SET_ACCEPTED_CALL',
  SET_ACTIVE_CATEGORY_INDEX: 'SET_ACTIVE_CATEGORY_INDEX',
  STORE_ACCESS_TOKEN: 'STORE_ACCESS_TOKEN',
  CLEAR_ACCESS_TOKEN: 'CLEAR_ACCESS_TOKEN',
  SET_ACTIVE_SEAT_ID: 'SET_ACTIVE_SEAT_ID',
  SET_IS_ORDERS_LOADING: 'SET_IS_ORDERS_LOADING',
  SET_IS_EDIT_PHONE_NUMBER: 'SET_IS_EDIT_PHONE_NUMBER',
};

export default actionTypes;
