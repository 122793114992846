import React from 'react';
import { unAssignedOrderListSelector } from '../../../pages/Drivers/selectors';
import UnAssignedSingleItem from './UnassignedSingleItem';
import { useDispatch, useSelector } from 'react-redux';

export const UnassignedList = () => {
  const unAssignedOrders = useSelector(unAssignedOrderListSelector);
  const dispatch = useDispatch();
  const { selectedUnAssignedOrders } = useSelector(
    (state) => state.driversReducer
  );
  const handleChange = (order) => {
    dispatch({
      type: 'toggleSelectedUnAssignedOrder',
      payload: order,
    });
  };

  return (
    <ul className="details-list">
      {unAssignedOrders
        ?.filter((order) => !!order.address)
        .map((order) => {
          return (
            <li key={order._id}>
              <UnAssignedSingleItem
                order={order}
                handleChange={handleChange}
                selected={selectedUnAssignedOrders.some(
                  (o) => o._id === order._id
                )}
                disabled={order.isThirdPartyPlatformDelivered}
              />
            </li>
          );
        })}
    </ul>
  );
};
