import { nextOrderConfig } from 'configuration/nextOrderConfig';
import SignIn from 'pages/SignIn/SignIn';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

const { tvAppBaseUrl } = nextOrderConfig;

export const DriversMap = () => {
  const fullScreen = useSelector((state) => state.dashboardReducer.fullScreen);
  const storeId = useSelector((s) => s.dashboardReducer.storeConfig?.storeId);
  const mapWidth = fullScreen ? window.innerWidth : 1028;
  const mapHeight = fullScreen ? window.innerHeight : 730;
  const cachedAuthCode = localStorage.getItem('authCode');
  const handleOnLoad = () => {
    refIframe.current.classList.add('show');
  };
  const mapURL = useMemo(
    () =>
      `${tvAppBaseUrl}/restaurant/instore?restaurant=${storeId}&code=${cachedAuthCode}`,
    [storeId]
  );

  let refIframe = useRef();

  if (!cachedAuthCode) return <SignIn relogin />;

  return (
    <div className="drivers-page-map">
      <iframe
        // key={authCode}
        ref={refIframe}
        onLoad={handleOnLoad}
        src={mapURL}
        width={mapWidth}
        height={mapHeight}
        title="Map"
      />
    </div>
  );
};
