const getMinifiedMenuItemForInstoreFun = (
  menuItem,
  menuItemSizes,
  menuItemModifiers,
  menuItemSubModifiers,
  orderType
) => {
  let currentItemObj = { ...menuItem };

  if (
    currentItemObj.itemType === 0 &&
    currentItemObj.selectedSizes &&
    currentItemObj.selectedSizes.length > 0
  ) {
    let isSingleSize = currentItemObj.selectedSizes.length === 1 ? true : false;

    //Find instore price for each size and update in current menu item
    currentItemObj.selectedSizes.forEach((size) => {
      let matchingSizePrices = menuItemSizes.filter((f) => {
        return f.sizeId === size._id && f.variantId === 'xxx';
      });
      if (matchingSizePrices.length > 0) {
        size.price = matchingSizePrices[0].orderTypes[orderType];
      }
      if (size?.variants?.length > 0) {
        size.variants = size.variants.map((v) => {
          let matchingSizeVariPrices = menuItemSizes.filter((f) => {
            return f.sizeId === size._id && f.variantId === v._id;
          });
          if (matchingSizeVariPrices.length > 0) {
            v.price = matchingSizeVariPrices[0].orderTypes[orderType];
          }
          return v;
        });
      }
      size.isSingleSize = isSingleSize;
    });
  } else if (
    currentItemObj.itemType === 1 &&
    currentItemObj.selectedModifiers &&
    currentItemObj.selectedModifiers.length > 0
  ) {
    // let subModifiersForSizes = [];
    currentItemObj.selectedModifiers.forEach((modifier) => {
      if (modifier.subModifiers) {
        if (modifier._id === currentItemObj.baseModifierId) {
          //BAse modifier pricing structure
          let isSingleSize = modifier.subModifiers.length === 1 ? true : false;
          modifier.subModifiers.forEach((subModifier) => {
            let matchingSubModifierPrices = menuItemSubModifiers.filter((f) => {
              return (
                f.modifierId === modifier._id &&
                f.subModifierId === subModifier._id &&
                f.otherSubModifierId === 'xxx' &&
                f.variantId === 'xxx'
              );
            });
            if (matchingSubModifierPrices.length > 0) {
              subModifier.price =
                matchingSubModifierPrices[0].orderTypes[orderType];
            }
            if (subModifier.variants) {
              subModifier.variants = subModifier.variants.map((v) => {
                let matchingSizeVariPrices = menuItemSubModifiers.filter(
                  (f) => {
                    return (
                      f.modifierId === modifier._id &&
                      f.subModifierId === subModifier._id &&
                      f.otherSubModifierId === 'xxx' &&
                      f.variantId === v._id
                    );
                  }
                );
                if (matchingSizeVariPrices.length > 0) {
                  v.price = matchingSizeVariPrices[0].orderTypes[orderType];
                }
                return v;
              });
            }
            subModifier.isSingleSize = isSingleSize;
          });
          // subModifiersForSizes = modifier.subModifiers;
        } else {
          modifier.subModifiers.forEach((subModifier) => {
            if (subModifier.prices) {
              subModifier.prices.forEach((price) => {
                let matchingSubModifierPrices = menuItemSubModifiers.filter(
                  (f) => {
                    return (
                      f.modifierId === modifier._id &&
                      f.subModifierId === subModifier._id &&
                      f.otherSubModifierId === price._id &&
                      f.variantId === 'xxx'
                    );
                  }
                );
                if (matchingSubModifierPrices.length > 0) {
                  price.price =
                    matchingSubModifierPrices[0].orderTypes[orderType];
                  subModifier.price =
                    matchingSubModifierPrices[0].orderTypes[orderType];
                }
                if (subModifier.variants) {
                  subModifier.variants = subModifier.variants.map((v) => {
                    let matchingSizeVariPrices = menuItemSubModifiers.filter(
                      (f) => {
                        return (
                          f.modifierId === modifier._id &&
                          f.subModifierId === subModifier._id &&
                          f.otherSubModifierId === price._id &&
                          f.variantId === v._id
                        );
                      }
                    );
                    if (matchingSizeVariPrices.length > 0) {
                      v.price = matchingSizeVariPrices[0].orderTypes[orderType];
                    }
                    return v;
                  });
                }
              });
            }
          });
        }
      }
    });
  }

  return currentItemObj;
};

export const getMinifiedMenuItemForInstore = getMinifiedMenuItemForInstoreFun;

const getMenuItemForInstoreFun = (
  menuItem,
  menuItemSizes,
  menuItemModifiers,
  menuItemSubModifiers
) => {
  let currentItemObj = { ...menuItem };

  if (
    currentItemObj.itemType === 0 &&
    currentItemObj.selectedSizes &&
    currentItemObj.selectedSizes.length > 0
  ) {
    let isSingleSize = currentItemObj.selectedSizes.length === 1 ? true : false;

    //Find instore price for each size and update in current menu item
    currentItemObj.selectedSizes.forEach((size) => {
      let matchingSizePrices = menuItemSizes.filter((f) => {
        return f.sizeId === size._id && f.variantId === 'xxx';
      });
      if (matchingSizePrices.length > 0 && matchingSizePrices[0].price) {
        size.price = matchingSizePrices[0].price;
      }
      if (size?.variants?.length > 0) {
        size.variants = size.variants.map((v) => {
          let matchingSizeVariPrices = menuItemSizes.filter((f) => {
            return f.sizeId === size._id && f.variantId === v._id;
          });
          if (
            matchingSizeVariPrices.length > 0 &&
            matchingSizeVariPrices[0].price
          ) {
            v.price = matchingSizeVariPrices[0].price;
          }
          return v;
        });
      }
      size.isSingleSize = isSingleSize;
    });
  } else if (
    currentItemObj.itemType === 1 &&
    currentItemObj.selectedModifiers &&
    currentItemObj.selectedModifiers.length > 0
  ) {
    // let subModifiersForSizes = [];
    currentItemObj.selectedModifiers.forEach((modifier) => {
      if (modifier.subModifiers) {
        if (modifier._id === currentItemObj.baseModifierId) {
          //BAse modifier pricing structure
          let isSingleSize = modifier.subModifiers.length === 1 ? true : false;
          modifier.subModifiers.forEach((subModifier) => {
            let matchingSubModifierPrices = menuItemSubModifiers.filter((f) => {
              return (
                f.modifierId === modifier._id &&
                f.subModifierId === subModifier._id &&
                f.otherSubModifierId === 'xxx' &&
                f.variantId === 'xxx'
              );
            });
            if (
              matchingSubModifierPrices.length > 0 &&
              matchingSubModifierPrices[0].price
            ) {
              subModifier.price = matchingSubModifierPrices[0].price;
            }
            if (subModifier.variants) {
              subModifier.variants = subModifier.variants.map((v) => {
                let matchingSizeVariPrices = menuItemSubModifiers.filter(
                  (f) => {
                    return (
                      f.modifierId === modifier._id &&
                      f.subModifierId === subModifier._id &&
                      f.otherSubModifierId === 'xxx' &&
                      f.variantId === v._id
                    );
                  }
                );
                if (
                  matchingSizeVariPrices.length > 0 &&
                  matchingSizeVariPrices[0].price
                ) {
                  v.price = matchingSizeVariPrices[0].price;
                }
                return v;
              });
            }
            subModifier.isSingleSize = isSingleSize;
          });
          // subModifiersForSizes = modifier.subModifiers;
        } else {
          modifier.subModifiers.forEach((subModifier) => {
            if (subModifier.prices) {
              subModifier.prices.forEach((price) => {
                let matchingSubModifierPrices = menuItemSubModifiers.filter(
                  (f) => {
                    return (
                      f.modifierId === modifier._id &&
                      f.subModifierId === subModifier._id &&
                      f.otherSubModifierId === price._id &&
                      f.variantId === 'xxx'
                    );
                  }
                );
                if (
                  matchingSubModifierPrices.length > 0 &&
                  matchingSubModifierPrices[0].price
                ) {
                  price.price = matchingSubModifierPrices[0].price;
                  subModifier.price = matchingSubModifierPrices[0].price;
                }
                if (subModifier.variants) {
                  subModifier.variants = subModifier.variants.map((v) => {
                    let matchingSizeVariPrices = menuItemSubModifiers.filter(
                      (f) => {
                        return (
                          f.modifierId === modifier._id &&
                          f.subModifierId === subModifier._id &&
                          f.otherSubModifierId === price._id &&
                          f.variantId === v._id
                        );
                      }
                    );
                    if (
                      matchingSizeVariPrices.length > 0 &&
                      matchingSizeVariPrices[0].price
                    ) {
                      v.price = matchingSizeVariPrices[0].price;
                    }
                    return v;
                  });
                }
              });
            }
          });
        }
      }
    });
  }

  return currentItemObj;
};

export const getMenuItemForInstore = getMenuItemForInstoreFun;
