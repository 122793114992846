import { PosIconLoading } from 'assets/icons/PosIconLoading';
import { PrinterContext } from 'context/PrinterContext';
import GeneralModal from 'modals/GeneralModal/index-new';
import { toggleBlurEffect } from 'pages/Dashboard/action';
import {
  currencySymbolSelector,
  storeConfigSelector,
  storeCountryIdentifierSelector,
} from 'pages/Dashboard/selectors';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllPrinters, printBillCommand } from 'services/ordersServices';
import { getAmountWithCountryCurrency } from 'util/currencyFormat';
import { getDateFromTimestamp, getShortenedDateTime } from 'util/dateUtils';
import {
  ButtonUI,
  CloseButtonCircleUI,
  ScrollableBoxUI,
} from '../../UI/components';
import { TabsNavigationUI } from '../../UI/components/TabsNavigationUI';
import './bem-print-order-modal.scss';
import { BillMenuSingleItem } from './BillMenuSingleItem';
import { DropdownPrinter } from './DropdownPrinter';
import { ReceiptItem } from './ReceiptItem';

const ORDER_TYPES = ['Walk In', 'Pick Up', 'Delivery', 'Dine-in'];

export const PrintOrderModal = ({ selectedOrder = {} }) => {
  const dispatch = useDispatch();
  const storeConfig = useSelector(storeConfigSelector);
  const { printBillModal } = useSelector((state) => state.ordersReducer);

  const customerName = `${selectedOrder?.firstName} ${selectedOrder?.lastName}`;
  const currencyCode = useSelector(currencySymbolSelector);
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);
  const accessToken = useSelector(
    (state) => state.dashboardReducer?.accessToken
  );

  const orderPlacedDatetime = getDateFromTimestamp(
    selectedOrder?.dateTimestamp
  );

  const {
    print,
    printers,
    deviceStatus,
    setPrinters,
    setPrinter,
    printer,
    setDefaultPrinter,
    getDefaultPrinter,
  } = useContext(PrinterContext);

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (ORDER_TYPES[Number(selectedOrder?.orderType)] === 'Dine-in') {
      setActiveTabIndex(1);
    }
  }, [selectedOrder?.orderType]);

  const closeModal = () => {
    dispatch({
      type: 'togglePlanBillModal',
      payload: false,
    });
  };

  const printBill = async (receiptType) => {
    let printObj = {
      printerId: printer?._id,
      restaurantId: selectedOrder?.restaurantId,
      orderId: selectedOrder?._id || selectedOrder?.id,
      storeId: storeConfig?.storeId,
      receiptType,
      openCashDrawer: false,
      isReprinting: true,
    };
    setIsLoading(true);
    await printBillCommand(printObj);
    print(printer?.ipAddress);
    setIsLoading(false);
    closeModal();
  };

  const tabsList = [
    {
      id: 0,
      title: 'Kitchen Docket',
      header: 'Kitchen Docket',
      handleSubmit: () => printers && printBill(0),
    },
    {
      id: 1,
      title: 'Customer Receipt',
      header: 'Tax Invoice',
      handleSubmit: () => printers && printBill(1),
    },
  ];

  const getActiveTab = () => {
    return { ...tabsList[activeTabIndex] };
  };

  const onPrinterChange = (e) => {
    const selectedId = e.target.value;
    printers.forEach((item) => {
      if (item._id === selectedId) {
        item.isPrimaryPrinter = true;
        setPrinter(item);
        setDefaultPrinter(item);
      } else {
        item.isPrimaryPrinter = false;
      }
    });
  };

  const getAllPrinters = async () => {
    const defaultPrinterId = getDefaultPrinter();
    const { printers } = await fetchAllPrinters(storeConfig,accessToken);
    setPrinters(printers);
    const defaultSelectedPrinter =
      printers.find((item) => {
        return item._id === defaultPrinterId;
      }) || printers[0];
    setPrinter(defaultSelectedPrinter);
    setDefaultPrinter(defaultSelectedPrinter);
  };

  const getAmount = (amount) => {
    return getAmountWithCountryCurrency({
      amount: Number(amount),
      locale: `en-${storeCountryIdentifier}`,
      currencyCode,
    });
  };

  useEffect(() => {
    if (printBillModal) {
      getAllPrinters();
    }
    // eslint-disable-next-line
  }, [printBillModal]);

  useEffect(() => {
    if (deviceStatus === 'success') {
      closeModal();
    }
    // eslint-disable-next-line
  }, [deviceStatus]);

  useEffect(() => {
    return () => {
      closeModal();
      dispatch(toggleBlurEffect(false));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <GeneralModal
      modalStatus={printBillModal && !!selectedOrder}
      toggleModal={closeModal}
      position="center"
      effect="move"
      overlay="blur"
      boxWidth="460px"
      boxHeight="698px"
      className="bem-print-order-modal"
      isResponsiveHeight
    >
      <div className="bem-print-order-modal__inner">
        <div className="bem-print-order-modal__header">
          <div className="bem-print-order-modal__header-top">
            <CloseButtonCircleUI buttonSize="m" onClick={closeModal} />
            <DropdownPrinter
              options={printers}
              onChange={onPrinterChange}
              selected={printer?._id}
            />
          </div>
          <h3 className="bem-print-order-modal__title">Print Order</h3>
          <TabsNavigationUI
            tabsList={tabsList}
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={setActiveTabIndex}
            style={{ marginLeft: -21, marginRight: -21 }}
          />
        </div>
        <div className="bem-print-order-modal__body">
          <ScrollableBoxUI>
            <div className="bem-print-order-modal__body-inner">
              <div className="bem-print-order-modal__order-box">
                <ReceiptItem
                  title={getActiveTab()?.header}
                  isHeader
                  isSingleItem
                />
                <ReceiptItem
                  title={`POS - ${
                    ORDER_TYPES[Number(selectedOrder?.orderType)]
                  }`}
                  description={
                    Number(selectedOrder?.orderType) === 3 &&
                    `Table ${selectedOrder?.tableNumber}`
                  }
                  isHeader
                />
                <ReceiptItem
                  title="Placed:"
                  description={getShortenedDateTime(orderPlacedDatetime)}
                />
                {Number(selectedOrder?.orderType) !== 3 && (
                  <ReceiptItem title="Customer:" description={customerName} />
                )}
                <ReceiptItem
                  title="Notes:"
                  description={selectedOrder?.notes || 'N/A'}
                />
                {selectedOrder?.menuItems?.map((menuItem, index) => {
                  return (
                    <BillMenuSingleItem
                      key={`BillMenuSingleItem-${index}`}
                      menuItem={menuItem}
                    />
                  );
                })}
                {Number(selectedOrder?.specialDiscount) > 0 && (
                  <ReceiptItem
                    title="Specials Discount:"
                    description={`-${getAmount(selectedOrder.specialDiscount)}`}
                    isFooter
                  />
                )}
                {Number(selectedOrder?.deliveryCost) > 0 && (
                  <ReceiptItem
                    title="Delivery Charges:"
                    description={getAmount(selectedOrder.deliveryCost)}
                    isFooter
                  />
                )}
                <ReceiptItem
                  title="Total Including Tax:"
                  description={getAmount(selectedOrder?.payableAmount)}
                  isTotal
                />
              </div>
            </div>
          </ScrollableBoxUI>
        </div>
        <div className="bem-print-order-modal__footer">
          <ButtonUI
            fullWidth
            sizeType="xl"
            rounded="m"
            onClick={getActiveTab()?.handleSubmit}
            icon={isLoading && <PosIconLoading mainColor="#ffffff" size={25} />}
            disabled={isLoading || !printers}
          >
            {`Print - ${getActiveTab()?.title}`}
          </ButtonUI>
        </div>
      </div>
    </GeneralModal>
  );
};
