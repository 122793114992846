import React, { useMemo } from 'react';
import { PosIconShapeMan } from '../../../assets/icons/PosIconShapeMan';
import moment from 'moment';

const UnAssignedSingleItem = ({
  order = {},
  handleChange = () => {}, // pass when not hideCheckbox
  selected = false, // pass when not hideCheckbox
  hideCheckbox = false,
  disabled = false,
}) => {
  const orderDurationAndClassName = useMemo(() => {
    if (order.deliveryDateTimestamp) {
      let cls = 'red';
      let hoursDue = '';
      let minutesDue = '';
      const timeDifference = Math.floor(
        (order.deliveryDateTimestamp - new Date().getTime()) / 60000
      );
      if (timeDifference > 59) {
        hoursDue = `${Math.floor(timeDifference / 60)} hour`;
        cls = 'green';
      } else {
        minutesDue = `${timeDifference} mins`;
        if (timeDifference >= 20) {
          cls = 'orange';
        }
      }
      return {
        priorityClass: cls,
        duration: hoursDue || minutesDue,
      };
    }
  }, [order.deliveryDateTimestamp]);

  const deliveryTime = moment(order.deliveryDate).format('h:mma');

  return (
    <label className="single-detail-box">
      {!hideCheckbox && (
        <div className="single-details-input-box">
          <input
            type="checkbox"
            name="unassigned_item"
            checked={selected}
            onChange={() => handleChange(order)}
            disabled={disabled}
          />
          <span className="checkstyle" />
        </div>
      )}
      <div className="detail-row row-1">
        <div className="detail-cell">
          <h3 className="name">{order.address}</h3>
        </div>
        <div className="detail-cell">
          <div className="status-box">
            <div
              className={
                'status-btn order-id ' + orderDurationAndClassName.priorityClass
              }
            >
              #{order.deliveryCode}
            </div>
          </div>
        </div>
      </div>
      <div className="detail-row row-2">
        <div className="detail-cell">
          <div>
            <span
              className={
                'text-status ' + orderDurationAndClassName.priorityClass
              }
            >
              Due in {orderDurationAndClassName.duration}
            </span>{' '}
            - {deliveryTime}
          </div>
        </div>
        <div className="detail-cell">
          <span className="client-name-box">
            <span className="client-name">
              {order.firstName} {order.lastName}
            </span>
            <PosIconShapeMan mainColor="#494b4c" darkModeColor="#ffffff" />
          </span>
        </div>
      </div>
      {disabled && (
        <div className="detail-row row-3">
          <div className="detail-cell">
            <span className="error-text">
              Cannot assign to driver, as delivery is managed by third party.
            </span>
          </div>
        </div>
      )}
    </label>
  );
};

export default UnAssignedSingleItem;
